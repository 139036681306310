.box {
    width: 100vw;
    height: 100vh;
  
    display: flex;
    justify-content: center;
    align-items: center;
   
}


.box-item {
    width: 900px;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
  }



.box-item3{
    width: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}
.box-item4{
    width: 500px;
    position: relative;
    margin-left: -50px;
}

.subtitle{
    width: 40%;
    text-align: center;
    font-family: 'AvenirNext-Demi';
    line-height: 1.5;
    font-size: 11pt;

}

.text{
    font-family: 'AvenirNext-Regular';
    text-align: center;
    text-align-last: center;
    font-size: 11pt;
    line-height: 1.5;
    width: 50%;
}
.contact{
    font-family: 'AvenirNext-Medium';
    width: 100%;
    font-size: 12pt;
    text-align: center;
}

a {
    color: black;
    text-decoration: none;
}

hr{
    width: 100%;
}
.fullSize{
    width: 50%;
}

.animation{
    margin-top: 10px;
    border: 7.5pt solid black;
    width: 90%;
}
.info{
    font-family: 'AvenirNext-Medium';
    line-height: 1.5;
    width: 90%;
    font-size: 16pt;
    margin-top: 5px;
    border: 7.5pt solid black;
    text-align: center;
    background-color: white;
}

.insta{
    width: 10.5mm;
    height: 10.5mm;
}

.insta:hover{
    cursor: pointer;
 
}
