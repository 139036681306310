.box-mobile {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-item-mobile {
    margin-top: 50px;
    width: 70%;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.subtitle-mobile {
    width: 100%;
    text-align: center;
    font-family: 'AvenirNext-Demi';
    line-height: 1.5;
    font-size: 10pt;

}

.animation-mobile{
    margin-top: 10px;
    border: 5.5pt solid black;
    width: 90%;
}

.info-mobile {
    font-family: 'AvenirNext-Medium';
    line-height: 1.5;
    width: 90%;
    font-size: 12pt;
    margin-top: 5px;
    border: 5.5pt solid black;
    text-align: center;
    background-color: white;
}

.text-mobile{
    font-family: 'AvenirNext-Regular';
    text-align: center;
    text-align-last: center;
    font-size: 11pt;
    line-height: 1.5;
    width: 90%;
}

.fullSize-mobile{
    width: 90%;
}